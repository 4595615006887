<template>
  <div class="tool-row">
    <div class="tool-nav" ref="navLeft" @click="right()">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 40"
        style="enable-background: new 0 0 20 40"
        xml:space="preserve"
      >
        <polygon points="20,40 20,0 0,20 " />
      </svg>
    </div>
    <div class="tool-row-body dragdealer" :id="rowIndex">
      <span class="row-title">{{ category.Title }}</span>
      <div class="handle tool-row-grid">
        <template v-for="(item, index) in category.Items">
          <AdviceBox
            :key="index"
            :item="item"
            v-if="item && item.Exists"
            :category="category.Title"
            :themeClass="theme"
            :active="checkedAdvice ? item.Advies : item.GemiddeldStart"
            @onClicked="showPopup(item)"
          />
        </template>
      </div>
      <span class="row-title right">{{ category.Title }}</span>
    </div>
    <div class="tool-nav" ref="navRight" @click="left()">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 40"
        style="enable-background: new 0 0 20 40"
        xml:space="preserve"
      >
        <polygon points="0,0 0,40 20,20 " />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdviceBox from "./AdviceBox.vue";

export default {
  props: {
    category: {},
    index: Number,
    theme: String,
  },
  components: {
    AdviceBox,
  },
  computed: {
    ...mapGetters({
      checkedAdvice: "ACTIVE_ADVICE",
      toolAdvicePosition: "ADVICE_POSITION",
    }),
    rowIndex() {
      return `test-${this.index}`;
    },
  },
  data() {
    return {
      drag: {},
      transformAmount: 0,
      currentIndex: 0,
      rightDisabled: false,
      leftDisabled: false,
      xPosition: null,
      yPosition: null,
    };
  },
  mounted() {
    this.dragHandler = this.$el.querySelector(".handle");
    this.$el.addEventListener("touchstart", this.handleTouchStart, false);
    this.$el.addEventListener("touchmove", this.handleTouchMove, false);
    this.$nextTick(() => {
      this.checkPositions();
    });
  },
  methods: {
    checkPositions() {
      setTimeout(() => {
        this.$children.forEach((child) => {
          const childEl = child.$el;
          const input = childEl.querySelector("input");
          //console.log(input);
          if (input.checked) {
            // Get the position of the box thats active/checked. 8.5 is the diff between if the box is inside the black bar
            let childPosition = childEl.getBoundingClientRect().left - 8.5;
            // Calc the difference of the black bar position and box position
            let diff =
              this.toolAdvicePosition - childPosition + this.transformAmount;
            // Move the bars so the active box is inside the black box
            this.dragHandler.style.transform = `translateX(${diff}px)`;
            this.transformAmount = diff;

            // console.log("child checked 2", childEl);
            // console.log("child position: ", childPosition);
            // console.log("tooladvice", this.toolAdvicePosition);
            // console.log("diff", diff);
            // console.log("transform amoutn", this.transformAmount);
            // console.log("--------------------------------------");
          }
        });
        this.getCurrentIndexOfActiveInput();
        this.setNavState();
      }, 100);
    },
    left() {
      if (!this.rightDisabled) {
        this.dragHandler.style.transform = `translateX(${
          this.transformAmount - 99
        }px)`;
        this.transformAmount -= 99;
        this.selectInput("left");
      }
    },
    right() {
      if (!this.leftDisabled) {
        this.dragHandler.style.transform = `translateX(${
          this.transformAmount + 99
        }px)`;
        this.transformAmount += 99;
        this.selectInput("right");
      }
    },
    getCurrentIndexOfActiveInput() {
      this.$children.forEach((child, index) => {
        const childEl = child.$el;
        const input = childEl.querySelector("input");
        if (input.checked) {
          this.currentIndex = index;
        }
      });
    },
    selectInput(direction) {
      this.getCurrentIndexOfActiveInput();
      if (direction === "right") {
        this.currentIndex--;
        const prevInput = this.$children[this.currentIndex].$el.querySelector(
          "input"
        );
        prevInput.checked = true;
      } else {
        this.currentIndex++;
        const nextInput = this.$children[this.currentIndex].$el.querySelector(
          "input"
        );
        nextInput.checked = true;
      }
      this.setNavState();
      this.$emit("navigated");
    },
    setNavState() {
      if (this.currentIndex === 0) {
        this.$refs.navLeft.classList.add("disabled");
        this.leftDisabled = true;
      } else {
        this.$refs.navLeft.classList.remove("disabled");
        this.leftDisabled = false;
      }

      if (this.currentIndex === this.$children.length - 1) {
        this.rightDisabled = true;
        this.$refs.navRight.classList.add("disabled");
      } else {
        this.$refs.navRight.classList.remove("disabled");
        this.rightDisabled = false;
      }
    },
    showPopup(item) {
      this.$emit("showPopup", item);
    },
    getTouches(evt) {
      return evt.touches || evt.originalEvent.touches;
    },
    handleTouchStart(evt) {
      const firstTouch = this.getTouches(evt)[0];
      this.xPosition = firstTouch.clientX;
      this.yPosition = firstTouch.clientY;
    },
    handleTouchMove(evt) {
      if (!this.xPosition) {
        return;
      }

      const xUp = evt.touches[0].clientX;
      const yUp = evt.touches[0].clientY;

      const xDiff = this.xPosition - xUp;
      const yDiff = this.yPosition - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          this.left();
        } else {
          this.right();
        }
      } else {
        return;
      }
      /* reset values */
      this.xPosition = null;
      this.yPosition = null;
    },
  },
};
</script>

<style lang="scss">
.tool-row {
  background-color: var(--bg-color-1);
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.tool-row-body {
  flex: 1 1 auto;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 0 17px;
}

.tool-row-grid {
  display: inline-grid;
  grid-template-columns: repeat(5, minmax(90px, 95px));
  grid-gap: 4px;
  position: relative;
  z-index: 2;
  cursor: grab;
  transition: transform 0.3s ease;

  // for ie
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    flex-wrap: wrap;

    .advice-box {
      margin: 0 2px;
      max-width: 95px;
    }
  }
}

.row-title {
  font-family: var(--font-family-heading);
  position: absolute;
  user-select: none;
  font-size: 33px;
  letter-spacing: 0.6px;
  color: white;
  top: 50%;
  left: 17px;
  transform: translateY(-50%);
  z-index: 1;

  &.right {
    right: 17px;
    left: auto;
  }
}

.tool-nav {
  padding: 16px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 40px;
  }

  &.disabled {
    svg {
      fill: var(--bg-color-2);
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}
</style>
