<template>
  <div class="tool-row loading"></div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.tool-row {
  background-color: var(--bg-color-1);
  margin-bottom: 8px;

  &.loading {
    height: 100px;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -150px;
      top: 0;
      height: 100%;
      width: 150px;
      background: linear-gradient(
        to right,
        transparent 0%,
        var(--bg-color-2) 50%,
        transparent 100%
      );
      animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }

  @keyframes load {
    from {
      left: -150px;
    }
    to {
      left: 100%;
    }
  }
}
</style>