<template>
  <div class="advice-box" :class="themeClass" @click="showPopup">
    <div class="measure">{{ item.Classificatie }}</div>
    <div class="heatloss">
      {{ getHeatloss }}
      <span class="kwh">kWh</span>
    </div>
    <div class="investment">{{ getInvestment }}</div>
    <input
      type="radio"
      class="hidden radio"
      :name="category"
      :checked="active"
    />
    <input
      type="text"
      class="hidden input-heatloss"
      :value="item.Warmtevraag"
    />
    <input
      type="text"
      class="hidden input-investment"
      :value="getInvestmentValue"
    />
    <input
      type="text"
      class="hidden input-savings"
      :value="item.BesparingPerJaar"
    />
    <span v-if="hasHover" class="more-info">Klik voor meer informatie</span>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
    category: String,
    themeClass: String,
    active: Boolean,
    hasHover: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getInvestment() {
      const investment = this.item.Investering - this.item.Subsidie;
      return investment === 0
        ? "-"
        : `€ ${investment.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
    },
    getInvestmentValue() {
      return this.item.Investering - this.item.Subsidie;
    },
    getHeatloss() {
      return this.item.Warmtevraag.toString().replace(".", ",");
    },
  },
  methods: {
    showPopup() {
      if (this.hasHover) {
        this.$emit("onClicked");
      }
    },
  },
};
</script>

<style lang="scss">
.advice-box {
  padding: 8px;
  color: white;
  max-width: 114px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  z-index: 2;
  position: relative;
  text-align: center;
  user-select: none;
  justify-content: center;
  position: relative;
  cursor: pointer;

  .heatloss {
    background-color: var(--accent-color-2);
    color: var(--primary-color-dark-2);
    padding: 0px 8px;
    margin: 2px 0;
    font-weight: 400;

    span {
      font-size: 12px;
    }
  }

  &.primary {
    background-color: var(--primary-color);
  }

  &.primary-light {
    background-color: var(--primary-color-light-1);
  }

  &.primary-light-2 {
    background-color: var(--primary-color-light-2);
  }

  &.primary-dark {
    background-color: var(--primary-color-dark-1);
  }

  &.primary-dark-2 {
    background-color: var(--primary-color-dark-2);
  }

  .more-info {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 170, 255, 0.9);
    align-items: center;
    justify-content: center;
    padding: 3px;
    line-height: 1;
    top: 0;
    left: 0;
  }

  .measure {
    font-size: 12px;
    line-height: 1.4;
    max-width: 100%;
  }

  &:hover {
    .more-info {
      display: flex;
    }
  }

  input[type="text"] {
    display: none;
  }

  input.hidden {
    visibility: hidden;
    position: absolute;
    left: -99999px;
  }
}
</style>
